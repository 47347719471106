<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12>
            <v-card>
                <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-data-table fixed-header height="750px" :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item }">
                        <tr :id="'r-' + item.OrderID">
                            <td v-if="!adminRole">{{ item.OrderDate | display_date('DD-MM-YYYY')}}</td>
                            <td v-else>
                                <v-edit-dialog :return-value.sync="item.OrderDate" large lazy persistent @save="saveItem({'OrderID': item.OrderID, 'OrderType': item.OrderType, 'OrderDate': format_date_sql(item.OrderDate)})">
                                    <div>{{ item.OrderDate | display_date('DD-MM-YYYY')}}</div>
                                    <template v-slot:input>
                                        <datepicker v-model="item.OrderDate" format="dd-MM-yyyy" @focus="true"></datepicker>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td>{{ item.CompletedDate | display_date('DD-MM-YYYY')}}</td>
                            <td>{{ item.CustomerName}}</td>
                            <td>{{ item.PurchaseName}}</td>
                            <td>{{ item.CSName}}</td>
                            <td><a target="blank" :href="(item.OrderType == 1 ? '/don-hang/' : (item.OrderType == 2 ? '/ky-gui/' : '/thanh-toan-ho/' )) + item.OrderID">{{ (item.OrderType == 1 ? 'MD' : (item.OrderType == 2 ? 'KG' : 'TT')) + item.OrderID}}</a></td>
                            <td>{{ item.OrderCode}}</td>
                            <td class="text-end">{{ item.ProductAmount | currency('¥', 2)}}</td>
                            <td class="text-end">{{ item.FeeShip | currency('¥', 2)}}</td>
                            <td v-if="show_fee_column" class="text-end">{{ item.FeeFragileGoods | currency('¥', 2)}}</td>
                            <td v-if="show_fee_column" class="text-end">{{ item.FeeCheck | currency('¥', 2)}}</td>
                            <td v-if="show_fee_column" class="text-end">{{ item.FeeFastShip | currency('¥', 2)}}</td>
                            <td class="text-end">
                                <div v-if="!adminRole && item.BackAmount > 0">{{ item.BackAmount | currency('¥',2)}}</div>
                                <v-edit-dialog v-else :return-value.sync="item.BackAmountTmp" large lazy persistent @save="saveItem({'OrderID': item.OrderID, 'OrderType': item.OrderType, 'BackAmount': item.BackAmountTmp})">
                                <div>{{ item.BackAmount | currency('¥',2)}}</div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.BackAmountTmp" type="number" step=".1" label="Hoàn phí shop" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-end">{{ item.TotalAmount | currency('¥', 2)}}</td>
                            <td class="text-end realAmount">
                                <div v-if="!adminRole && item.RealProductAmount > 0">{{ item.RealProductAmount | currency('¥',2)}}</div>
                                <v-edit-dialog v-else :return-value.sync="item.RealProductAmountTmp" large lazy persistent @save="saveItem({'OrderID': item.OrderID, 'OrderType': item.OrderType, 'RealProductAmount': item.RealProductAmountTmp})">
                                <div>{{ item.RealProductAmount | currency('¥',2)}}</div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.RealProductAmountTmp" type="number" step=".1" label="Phí mua hàng" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-end realAmount">
                                <div v-if="!adminRole && item.RealFeeShip > 0">{{ item.RealFeeShip | currency('¥',2)}}</div>
                                <v-edit-dialog v-else :return-value.sync="item.RealFeeShipTmp" large lazy persistent @save="saveItem({'OrderID': item.OrderID, 'OrderType': item.OrderType, 'RealFeeShip': item.RealFeeShipTmp})">
                                <div>{{ item.RealFeeShip | currency('¥',2)}}</div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.RealFeeShipTmp" type="number" step=".1" label="Phí ship" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td v-if="show_fee_column" class="text-end realAmount">
                                <div v-if="!adminRole && item.RealFeeFragileGoods > 0">{{ item.RealFeeFragileGoods | currency('¥',2)}}</div>
                                <v-edit-dialog v-else :return-value.sync="item.RealFeeFragileGoodsTmp" large lazy persistent @save="saveItem({'OrderID': item.OrderID, 'OrderType': item.OrderType, 'RealFeeFragileGoods': item.RealFeeFragileGoodsTmp})">
                                <div>{{ item.RealFeeFragileGoods | currency('¥',2)}}</div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.RealFeeFragileGoodsTmp" type="number" step=".1" label="Phí gia cố" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td v-if="show_fee_column" class="text-end realAmount">
                                <div v-if="!adminRole && item.RealFeeCheck > 0">{{ item.RealFeeCheck | currency('¥',2)}}</div>
                                <v-edit-dialog v-else :return-value.sync="item.RealFeeCheckTmp" large lazy persistent @save="saveItem({'OrderID': item.OrderID, 'OrderType': item.OrderType, 'RealFeeCheck': item.RealFeeCheckTmp})">
                                <div>{{ item.RealFeeCheck | currency('¥',2)}}</div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.RealFeeCheckTmp" type="number" step=".1" label="Phí kiểm hàng" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td v-if="show_fee_column" class="text-end realAmount">
                                <div v-if="!adminRole && item.RealFastShip > 0">{{ item.RealFastShip | currency('¥',2)}}</div>
                                <v-edit-dialog v-else :return-value.sync="item.RealFastShipTmp" large lazy persistent @save="saveItem({'OrderID': item.OrderID, 'OrderType': item.OrderType, 'RealFastShip': item.RealFastShipTmp})">
                                <div>{{ item.RealFastShip | currency('¥',2)}}</div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.RealFastShipTmp" type="number" step=".1" label="Phí hỏa tốc" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-end realAmount">
                                <div v-if="!adminRole && item.RealBackAmount > 0">{{ item.RealBackAmount | currency('¥',2)}}</div>
                                <v-edit-dialog v-else :return-value.sync="item.RealBackAmountTmp" large lazy persistent @save="saveItem({'OrderID': item.OrderID, 'OrderType': item.OrderType, 'RealBackAmount': item.RealBackAmountTmp})">
                                <div>{{ item.RealBackAmount | currency('¥',2)}}</div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.RealBackAmountTmp" type="number" step=".1" label="Hoàn phí khách" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-end realAmount">{{ item.RealTotalAmount | currency('¥', 2)}}</td>
                            <td class="text-end" v-bind:style="{ 'color': item.DiffAmount < 0 ? 'red' : ''}">{{ item.DiffAmount | currency('¥', 2)}}</td>
                            <td v-if="item.OrderType != 3" v-bind:style="{ 'color': get_status_color(item.OrderStatus, order_status)}">{{ item.OrderStatus | display_value(order_status) }}</td>
                            <td v-if="item.OrderType == 3" v-bind:style="{ 'color': 'blue'}">Đã thanh toán</td>
                            <td class="text-center"><v-checkbox primary hide-details v-model="item.IsGetDiscount" @change="saveItem({'OrderID': item.OrderID, 'OrderType': item.OrderType, 'IsGetDiscount': item.IsGetDiscount})"></v-checkbox></td>
                        </tr>
                    </template>
                    <template v-slot:body.append>
                        <tr class="total-summary-td">
                            <td colspan="7" class="text-center font-weight-bold">Tổng</td>
                            <td class="text-end font-weight-bold">{{ summary.ProductAmount | currency('', 2)}}</td>
                            <td class="text-end font-weight-bold">{{ summary.FeeShip | currency('', 2)}}</td>
                            <td v-if="show_fee_column" class="text-end font-weight-bold">{{ summary.FeeFragileGoods | currency('', 2)}}</td>
                            <td v-if="show_fee_column" class="text-end font-weight-bold">{{ summary.FeeCheck | currency('', 2)}}</td>
                            <td v-if="show_fee_column" class="text-end font-weight-bold">{{ summary.FeeFastShip | currency('', 2)}}</td>
                            <td class="text-end font-weight-bold">{{ summary.BackAmount | currency('', 2)}}</td>
                            <td class="text-end font-weight-bold">{{ summary.TotalAmount | currency('', 2)}}</td>
                            <td class="text-end font-weight-bold">{{ summary.RealProductAmount | currency('', 2)}}</td>
                            <td class="text-end font-weight-bold">{{ summary.RealFeeShip | currency('', 2)}}</td>
                            <td v-if="show_fee_column" class="text-end font-weight-bold">{{ summary.RealFeeFragileGoods | currency('', 2)}}</td>
                            <td v-if="show_fee_column" class="text-end font-weight-bold">{{ summary.RealFeeCheck | currency('', 2)}}</td>
                            <td v-if="show_fee_column" class="text-end font-weight-bold">{{ summary.RealFastShip | currency('', 2)}}</td>
                            <td class="text-end font-weight-bold">{{ summary.RealBackAmount | currency('', 2)}}</td>
                            <td class="text-end font-weight-bold">{{ summary.RealTotalAmount | currency('', 2)}}</td>
                            <td class="text-end font-weight-bold" v-bind:style="{ 'color': summary.DiffAmount < 0 ? 'red' : ''}">{{ summary.DiffAmount | currency('', 2)}}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "@/configs";
import Filtering from "@/components/Filtering";
import Datepicker from "vuejs-datepicker";
import moment from "moment"
import _ from "lodash";

export default {
    components: {
        "hpo-filter": Filtering,
        Datepicker
    },
    data() {
        return {
            order_status: configs.ORDER_STATUS,
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            viewName: "accountantorder_list",
            filterConditions: [],
            quickFilterConditions: [],
            requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
            show_fee_column: configs.SYSTEM_CONFIG.accountant_order_fee_column
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        }
    },
    computed: {
        ...mapState({
            items: state => state.accountant_order.all.data,
            total_rows: state => state.accountant_order.all.total,
            summary: state => state.accountant_order.all.summary,
            loading: state => state.accountant_order.loading
        }),
        tableConfig() {
            let pagConfig = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            pagConfig.pagination.sortBy = pagConfig.pagination.sortBy[0] == 'ID' ? ['OrderID'] : pagConfig.pagination.sortBy;
            return pagConfig;
        },
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        paymentRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment;
        },
        headers() {
            let v_headers = [
                {
                    text: "Ngày đặt hàng",
                    value: "OrderDate",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["Date"]
                },
                {
                    text: "Ngày hoàn thành",
                    value: "CompletedDate",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["Date"]
                },
                {
                    text: "Khách hàng",
                    value: "CustomerName",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Mua hàng",
                    value: "PurchaseName",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Sale",
                    value: "CSName",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Mã đơn hàng",
                    value: "OrderID",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Mã đơn TQ",
                    value: "OrderCode",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Tiền hàng",
                    value: "ProductAmount",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Phí ship",
                    value: "FeeShip",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                } ];

                if(configs.SYSTEM_CONFIG.accountant_order_fee_column) {
                    v_headers.push({
                        text: "Gia cố",
                        value: "FeeFragileGoods",
                        filterable: true,
                        align: "end",
                        dataType: configs.DATA_TYPE["Number"]
                    });
                    v_headers.push({
                        text: "Kiểm hàng",
                        value: "FeeCheck",
                        filterable: true,
                        align: "end",
                        dataType: configs.DATA_TYPE["Number"]
                    });
                    v_headers.push({
                        text: "Hỏa tốc",
                        value: "FeeFastShip",
                        filterable: true,
                        align: "end",
                        dataType: configs.DATA_TYPE["Number"]
                    });
                }
                v_headers.push({
                    text: "Hoàn khách",
                    value: "BackAmount",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                });
                v_headers.push({
                    text: "Tổng phí",
                    value: "TotalAmount",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                });
                v_headers.push({
                    text: "Tiền hàng (TC)",
                    value: "RealProductAmount",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                });
                v_headers.push({
                    text: "Phí ship (TC)",
                    value: "RealFeeShip",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                });
                if(configs.SYSTEM_CONFIG.accountant_order_fee_column) {
                    v_headers.push({
                        text: "Gia cố (TC)",
                        value: "RealFeeFragileGoods",
                        filterable: true,
                        align: "end",
                        dataType: configs.DATA_TYPE["Number"]
                    });
                    v_headers.push({
                        text: "Kiểm hàng (TC)",
                        value: "RealFeeCheck",
                        filterable: true,
                        align: "end",
                        dataType: configs.DATA_TYPE["Number"]
                    });
                    v_headers.push({
                        text: "Hỏa tốc (TC)",
                        value: "RealFastShip",
                        filterable: true,
                        align: "end",
                        dataType: configs.DATA_TYPE["Number"]
                    });
                }
                v_headers.push({
                    text: "Shop hoàn",
                    value: "RealBackAmount",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                });
                v_headers.push({
                    text: "Tổng phí (TC)",
                    value: "RealTotalAmount",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                });
                v_headers.push({
                    text: "Chênh lệch",
                    value: "DiffAmount",
                    filterable: true,
                    align: "end",
                    dataType: configs.DATA_TYPE["Number"]
                });
                v_headers.push({
                    text: "Trạng thái",
                    value: "OrderStatus",
                    filterable: true,
                    values: configs.ORDER_STATUS,
                    dataType: configs.DATA_TYPE["Enum"]
                });
                v_headers.push({
                    text: "Đã đối soát",
                    value: "IsGetDiscount",
                    filterable: true,
                    values: [
                        {value: 0, name: 'Chưa đối soát'},
                        {value: 1, name: 'Đã đối soát'},
                    ],
                    dataType: configs.DATA_TYPE["Enum"]
                });
            return v_headers;
        }
    },
    methods: {
        createData: function () {
            this.$store.dispatch("accountant_order/export", this.tableConfig);
        },
        filter_data: function () {
            this.$store.dispatch("accountant_order/getList", this.tableConfig);
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy
            let sortDesc = this.pagination.sortDesc
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy == ['ID'] ? ['OrderID'] : sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        saveItem(obj) {
            this.$store.dispatch("accountant_order/setDetail", {param: this.tableConfig, data: obj, id: obj.OrderID});
        },
        get_status_color(val, enums) {
            return this.$options.filters.display_color(val, enums);
        },
        format_date_sql(val) {
            return moment(val).startOf('d').format(configs.SQL_DATE_FORMAT)
        } 
    },
    mounted() {
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_CREATE_DATA", this.createData);
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
            this.$store.dispatch("feeBuy/removeDetail", id);
            setTimeout(this.filter_data, 200);
        });
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_CREATE_DATA", this.createData);
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$off("CONFIRMED_REMOVE_DATA");
    }
};
</script>
<style scoped>
.realAmount {
    background-color: #f7ffd5;
}
</style>
